@import url("assets/css/bootstrap.min.css");
@import url("assets/css/magnific-popup.css");
@import url("assets/css/owl.carousel.min.css");
@import url("assets/css/owl.theme.default.css");
@import url("assets/css/nice-select.css");
@import url("assets/glyphter-font/css/Glyphter.css");
@import url("assets/css/animate.css");
@import url("assets/css/all.min.css");
@import url("assets/css/main.css");

body {
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  /* background: #77b; */
}

.purple-top-border {
  border-top: 2px solid var(--startable);
}

a {
  text-decoration: none !important;
}