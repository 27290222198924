.deposit__complate {
  border: 2px solid #76287D;
  padding: 52px 52px;
  /* background: var(--slidebox); */
  background: linear-gradient(to right, #EB46A5, #6649F6);

  border-radius: 10px;
  text-align: center;
  margin: 0 5px;
}


.deposit__complate_ad {
  border: 2px solid #76287D;
  padding: 15px 15px;
  /* background: var(--slidebox); */
  border-radius: 10px;
  text-align: center;
  justify-content: center;
  /* margin: 0 5px; */
}

.message {
  /* Common styles for messages */
  font-size: 16px;
  margin-top: 10px;
}

.warning {
  /* Styles for success message */
  color: #ffc107;
}

.success {
  /* Styles for success message */
  color: #198754;
}
.danger {
  /* Styles for danger message */
  color: #dc3545;
}
